import styled from 'styled-components';

export const OverviewAdditionalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    min-width: 269px;

    @media (max-width: 1265px) {
        width: 40%;
    }
    @media (max-width: 960px) {
        width: 40%;
    }
`;

export const HoursHeader = styled.div`
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: #4c6372;
    font-family: Heebo;
`;
export const HoursList = styled.div`
    width: 100%;
    margin-top: 13px;
`;

export const HourListItem = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
`;

export const AdditionalMediaContent = styled.div`
    margin-top: 49px;
`;