import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass/styled-components';
import { Step } from './styledStepper';

const Stepper = ({ children, initialStep, nav, sideNav, containerStyles }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [classes, setClasses] = useState({});

  const transitions = {
    enterRight: `animated fadeInRight`,
    enterLeft: `animated fadeInLeft`,
    exitRight: `animated fadeOutRight`,
    exitLeft: `animated fadeOutLeft`,
  };

  // sets initial steps
  useEffect(() => {
    const stepItems = React.Children.toArray(children);
    setSteps(stepItems);
    const defaultStep = initialStep - 1;

    // sets activeStep to initialStep if it exists
    if (defaultStep && stepItems[defaultStep]) {
      setCurrentStep(defaultStep);
    }
  }, [initialStep, children]);

  const isInvalidStep = (nextStep) => nextStep < 0 || nextStep > steps.length - 1;

  const setActiveStep = (nextStep) => {
    const active = currentStep;
    if (active === nextStep) return;
    if (isInvalidStep(nextStep)) {
      console.error(`${nextStep + 1} is an invalid step`);
      return;
    }

    if (active < nextStep) {
      classes[active] = transitions.exitLeft;
      classes[nextStep] = transitions.enterRight;
    } else {
      classes[active] = transitions.exitRight;
      classes[nextStep] = transitions.enterLeft;
    }

    setClasses(classes);
    setCurrentStep(nextStep);
  };

  const goToStep = (step) => setActiveStep(step);

  const firstStep = () => goToStep(1);

  const nextStep = () => setActiveStep(currentStep + 1);

  const previousStep = () => setActiveStep(currentStep - 1);

  const isReactComponent = ({ type }) => typeof type === 'function' || typeof type === 'object';

  // props we want to pass to stepper children components and custom nav component
  const childrenProps = {
    currentStep,
    totalSteps: steps.length,

    // functions
    firstStep,
    nextStep,
    goToStep,
    previousStep,
  };

  const childrenWithProps = React.Children.map(steps, (child, i) => {
    if (!child) return null;

    childrenProps.isActive = i === currentStep;
    childrenProps.transitions = classes[i];

    return (
      <Step className={`step ${childrenProps.transitions}`} active={childrenProps.isActive} {...childrenProps}>
        {isReactComponent(child) ? React.cloneElement(child, childrenProps) : child}
      </Step>
    );
  });

  return (
    <div style={{ positon: 'relative' }}>
      {nav && React.cloneElement(nav, childrenProps)}
      <Flex
        className="step-container"
        flexDirection={['column', null, null, 'row']}
        sx={{ ...containerStyles, overflow: 'hidden' }}
      >
        {sideNav && React.cloneElement(sideNav, childrenProps)}
        <div className="step-content" style={{ position: 'relative', width: '100%' }}>
          {childrenWithProps}
        </div>
      </Flex>
    </div>
  );
};

export default Stepper;

Stepper.propTypes = {
  children: PropTypes.node,
  initialStep: PropTypes.number,
  nav: PropTypes.node,
  sideNav: PropTypes.node,
  containerStyles: PropTypes.object,
};

Stepper.defaultProps = {
  initialStep: 1,
  children: [],
  nav: null,
  sideNav: null,
  containerStyles: {},
};
