import React from 'react';
import { Router, Location } from '@reach/router';
import ListingPage from '../components/organisms/ListingPage/ListingPage';

import SimpleReactLightbox from 'simple-react-lightbox';
import Layout from '../layouts';

const App = () => {
  return (
    <Layout px={[0, null, 5]} includeSearchBar={true}>
      <SimpleReactLightbox style={{ zIndex: 1 }}>
        <WithLocationRouter>
          <ListingPage location={typeof window !== `undefined` ? location : null} path="/" />
          <ListingPage path="view/:slug" />
        </WithLocationRouter>
      </SimpleReactLightbox>
    </Layout>
  );
};

const WithLocationRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <Router location={location} className="router" style={{ width: '100%' }} basepath="/business">
        {children}
      </Router>
    )}
  </Location>
);
export default App;
