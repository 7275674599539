import styled from 'styled-components';
import React from 'react';
import { Box, Text } from 'rebass/styled-components';

const listingContentExists = (label, listing) => {
  if (label === 'Overview') {
    return true
  } else {
    if (listing.content) {
      const anyContent = listing.content.find((item) => item.type === label.toUpperCase())
      return anyContent ? true : false;
    }
    return false;
  }
};

export const MenuNav = ({ data, goToStep, direction, display, listing }) => {
  return (
    <Box
      as="nav"
      className="nav-container"
      display={display}
      mr={[0, null, 12]}
      minWidth="fit-content"
      sx={{ flexDirection: direction, justifyContent: direction === 'column' ? 'flex-start' : 'space-between' }}
    >
      {data.map((item, i) => (
        <Text
          className="nav-item"
          width={1}
          key={item.label}
          color={listingContentExists(item.label, listing) ? 'primary' : 'disabled'}
          onClick={() => {
            if (listingContentExists(item.label, listing)) {
              goToStep(i)
            }
          }}
          fontSize={3}
          pb={direction === 'column' ? 2 : 7}
          textAlign={direction === 'column' ? 'left' : 'center'}
          opacity={listingContentExists(item.label, listing) ? '100$' : '60%'}
          sx={listingContentExists(item.label, listing) ? { cursor: 'pointer' } : ''}
        >
          {item.label}
        </Text>
      ))}
    </Box>
  );
};

export const OverviewAdditionalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;

  @media (max-width: 1200px) {
    width: 40%;
  }
  @media (max-width: 960px) {
    width: 80%;
  }
  @media (max-width: 460px) {
    transform: translateX(-17%);
  }
`;

export const ListingDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 17px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const ListingDetails = styled.div`
  margin-left: 48px;

  @media (max-width: 580px) {
    margin-left: 0px;
  }
`;

export const ImagesWrapper = styled.div`
  @media (max-width: 1230px) {
    order: 2;
  }
  @media (max-width: 880px) {
    order: 1;
  }
  @media (max-width: 330px) {
    margin-left: -20px;
  }
`;
