import React from 'react';
import ExpandedMenu, { SpecialOfferHeading } from './StyledSpecialOffer';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Star from '../../../../assets/58.svg';

const SpecialOffer = ({ classes, specialOffer, expandedText, expanded, handlePanelChange }) => (
  <ExpandedMenu>
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary>
        <img src={Star} className="Star" alt="" />
        <SpecialOfferHeading> {specialOffer.title}</SpecialOfferHeading>
        <span className="Expander" onClick={handlePanelChange}>
          {' '}
          {expandedText}{' '}
        </span>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <p>{specialOffer.description}</p>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </ExpandedMenu>
);
//onChange={handlePanelChange( expanded ? false : true) }
export default SpecialOffer;
