import React from 'react';
import { Flex, Box, Image, Text } from 'rebass/styled-components';

const MenuContent = ({ label, listing, handleLoadError, Placeholder, labelFormatter }) => {
  return (
    <Flex flexDirection="column" className={label}>
      <Text variant="heading2">{label}:</Text>
      <Flex flexDirection="column">
        {listing.content && listing.content.length
          ? listing.content.map((item, index) => {
              if (item.type === label.toUpperCase()) {
                return (
                  <Flex flexDirection={['row']} key={index} mb="48px" mt={5}>
                    <Box mr="30px" sx={{ minWidth: '130px' }}>
                      {item.image !== null ? (
                        <Image sx={{ maxWidth: '130px' }} src={item.image.url} alt="" onError={handleLoadError} />
                      ) : (
                        <Image src={Placeholder} alt="" onError={handleLoadError} />
                      )}
                    </Box>
                    <Flex flexDirection="column">
                      <Text variant="heading3">{labelFormatter ? labelFormatter(item) : label}</Text>
                      <Text variant="p">{item.description}</Text>
                    </Flex>
                  </Flex>
                );
              }
              return '';
            })
          : ''}
      </Flex>
    </Flex>
  );
};

export default MenuContent;
