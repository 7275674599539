import React from 'react';
import moment from 'moment';
import { HourListItem, HoursList, AdditionalMediaContent, HoursHeader, OverviewAdditionalContent } from './StyledHours';
import { Text, Flex } from 'rebass/styled-components';

const HoursContainer = ({ listing }) => (
  <OverviewAdditionalContent>
    <HoursHeader>Hours of Operation:</HoursHeader>
    <Flex width={1} flexDirection="column" mt={4}>
      {listing.hours && listing.hours.length > 0 ? (
        listing.hours.map((item, ind) => {
          const day = item.day.toLowerCase();
          const fDay = day
            .split('')
            .map((letter, ind) => (ind === 0 ? letter.toUpperCase() : letter))
            .join('');

          return (
            <Flex width={1} flexDirection="row" justifyContent="space-between" key={ind} mb={2}>
              <span>{`${fDay}`}</span>
              <Flex flexDirection="column">
                {item.periods.map((period, index) => (
                  <span>
                    {item.closed
                      ? 'Closed'
                      : `${moment(period.from, 'HH:mm').format('h:mm a')} - ${moment(period.to, 'HH:mm').format(
                          'h:mm a',
                        )}`}
                  </span>
                ))}
              </Flex>
            </Flex>
          );
        })
      ) : (
        <Text variant="p">No information available</Text>
      )}
    </Flex>
    {/* <ContentListingLine /> */}
    <AdditionalMediaContent />
  </OverviewAdditionalContent>
);
export default HoursContainer;
