import styled from 'styled-components';

export const Step = styled.div`
  opacity: ${({ active }) => (active ? 1 : 0)};
  pointer-events: ${({ active }) => (active ? 'inherit' : 'none')};
  position: ${({ active }) => (active ? 'relative' : 'absolute')};
  top: 0;
  width: 100%;

  /* Lowers animation speed  */
  animation-duration: 0.5s !important;
`;
