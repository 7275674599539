import React from 'react';
import NumberFormat from 'react-number-format';
import { Flex, Text, Box } from 'rebass/styled-components';

const map = {
  amex: 'American Express',
  masterCard: 'Mastercard',
  visa: 'Visa',
  discover: 'Discover',
  debitCard: 'Debit Card',
  cash: 'Cash',
  check: 'Check',
  dinersClub: "Diner's Club",
  invoice: 'Invoice',
  storeCard: 'Store Card',
};
const Overview = ({ listing }) => {
  const phoneNumber = listing.phones[0].number.replace('+1', '');
  return(
    <Flex flexDirection={['column']} flexWrap="wrap" className="overview-container">
    {listing.description ? (
      <Box>
        <Text variant="heading3">Description:</Text>
        <Text mb={5} variant="p">
          {listing.description}
        </Text>
      </Box>
    ) : (
      ''
    )}
    {listing.paymentOptions && listing.paymentOptions.length ? (
      <Box>
        <Text variant="heading3">Payment Types:</Text>
        <Box mb={5}>
          <Text variant="p">
            {listing.paymentOptions.map((item, index, array) => (
              <span variant="p" key={index}>
                {index === array.length - 1 ? `${map[`${item}`]}` : `${map[`${item}`]}, `}
              </span>
            ))}
          </Text>
        </Box>
      </Box>
    ) : (
      ''
    )}
    {listing.services && listing.languages.length ? (
      <Box>
        <Text variant="heading3">Services:</Text>
        <Box mb={5}>
          <Text variant="p">
            {listing.services.map((item, index, array) => (
              <span key={index}>{index === array.length - 1 ? `${item}` : `${item}, `}</span>
            ))}
          </Text>
        </Box>
      </Box>
    ) : (
      ''
    )}
    {listing.languages && listing.languages.length ? (
      <Box>
        <Text variant="heading3">Languages:</Text>
        <Box mb={5}>
          <Text variant="p">
            {listing.languages.map((item, index, array) => (
              <span>{index === array.length - 1 ? `${item}` : `${item}, `}</span>
            ))}
          </Text>
        </Box>
      </Box>
    ) : (
      ''
    )}
    {listing.categories && listing.categories.length ? (
      <Box>
        <Text variant="heading3">Categories:</Text>
        <Box mb={5}>
          <Text variant="p">
            {listing.categories.map((item, index, array) => (
              <span key={index}> {index === array.length - 1 ? `${item.name}` : `${item.name},`} </span>
            ))}
          </Text>
        </Box>
      </Box>
    ) : (
      ''
    )}
    {listing.keywords && listing.keywords.length ? (
      <Box>
        <Text variant="heading3">Keywords:</Text>
        <Box mb={5}>
          <Text variant="p">
            {listing.keywords.map((item, index, array) => (
              <span key={index}>{index === array.length - 1 ? `${item}` : `${item}, `}</span>
            ))}
          </Text>
        </Box>
      </Box>
    ) : (
      ''
    )}
    <Text variant="heading3">Additional Contact Info:</Text>
    <Box mb={5}>
      {listing.phones && listing.phones.length
        ? listing.phones.map((item, ind) => (
            <Text variant="p" key={ind}>
              {item.type === 'FAX' ? 'Fax:' : 'Phone:'}{' '}
              {<NumberFormat value={phoneNumber} format="(###) ###-####" displayType="text" />}
            </Text>
          ))
        : ''}
    </Box>
  </Flex>
  )
};
export default Overview;
