import React, { useEffect, useState } from 'react';
import { Flex, Box, Image, Text } from 'rebass/styled-components';
import Loader from '../../molecules/ListingPage/Loader/Loader';
import MainInfo from '../../molecules/ListingPage/MainInfo/MainInfo';
import Overview from '../../molecules/ListingPage/Overview/Overview';
import Placeholder from '../../../assets/fill-52.svg';
import listingSvc from '../../../services/listingSvc';
import CarouselGenerator from '../../atoms/Carousel/Carousel';
import SpecialOffer from '../../molecules/ListingPage/SpecialOffer/SpecialOffer';
import { ImagesWrapper } from './StyledListingPage';
import Stepper from '../../molecules/Stepper/stepper';
import { MenuNav } from './StyledListingPage';
import Banner from '../../atoms/Banner/banner';
import HoursContainer from '../../molecules/ListingPage/Hours/Hours';
import MenuContent from '../../molecules/ListingPage/MenuContent';
import moment from 'moment';

const labelFormatters = {
  Events: (item) => `${moment(item.timeStart).format('MMM Do YY')}, ${item.title}`,
  Products: (item) => `${item.title} ${item.category ? `${item.category}` : ''} ${item.price ? `$ ${item.price}` : ''}`,
  People: (item) =>
    `${item.name ? `${item.name}` : ''} ${item.name && item.title ? `|` : ''} ${item.title ? `${item.title}` : ''}`,
  Menu: (item) => `${item.title} ${item.category ? `- ${item.category} ` : ''}${item.price ? `$ ${item.price}` : ''}`,
};

const navData = [
  { label: 'Overview', component: <Overview /> },
  { label: 'Events', component: <MenuContent />, labelFormatter: labelFormatters.Events },
  { label: 'Products', component: <MenuContent />, labelFormatter: labelFormatters.Products },
  { label: 'Menu', component: <MenuContent />, labelFormatter: labelFormatters.Menu },
  { label: 'People', component: <MenuContent />, labelFormatter: labelFormatters.People },
];

const ContentListing = (props) => {
  const [listing, setListing] = useState({});
  const [socialProfiles, setSocialProfiles] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [expandedText, setExpandedText] = useState('See More');
  const [fetching, setFetching] = useState(true);
  const [logo, setLogo] = useState(null);
  const { classes, slug } = props;

  useEffect(() => {
    async function anonFunc() {
      const listing = await listingSvc().getListingBySlug(slug);

      if (listing != undefined) {
        setListing(listing);
      } else {
        setListing({
          name: '404 No listing found',
          images: false,
        });
      }

      setFetching(false);

      if (listing && listing.images && listing.images.length > 0) {
        let foundLogo = listing.images.filter((img) => img.type === 'LOGO');
        if (foundLogo.length) {
          setLogo(foundLogo[0].url);
        } else {
          setLogo(listing.images[0].url);
        }
      } else {
        setLogo(Placeholder);
      }
    }
    anonFunc();
  }, []);

  const handlePanelChange = (event) => {
    expanded === true ? setExpanded(false) : setExpanded(true);
    expanded === true ? setExpandedText('See More') : setExpandedText('See Less');
  };

  const handleLoadError = (e) => {
    e.target.src = Placeholder;
  };

  if (fetching) {
    return <Loader Banner={Banner} />;
  }

  return (
    <Flex flexDirection="column" alignItems="center" width={1}>
      <Banner />
      <Flex
        className="business-page-container"
        flexDirection="column"
        alignItems="center"
        maxWidth={1170}
        width={1}
        bg="white"
        sx={{ boxShadow: '0 3px 8px 0 rgba(106, 126, 150, 0.14)', borderRadius: '4px' }}
        mt={[5, 60, null, 140]}
        px={[5, null, 13]}
        py={[10, null, 15]}
      >
        <Box className="business-page-name" width={1} pb={[4]} sx={{ borderBottom: '1px solid #dbe6ed' }}>
          <Text textAlign="left" variant="heading2" as="h2">
            {listing.name || ''}
          </Text>
        </Box>
        <Flex className="business-page-content" width={1} flexDirection="column" mt={[10, null, 13]}>
          <Flex flexDirection={['column', null, null, 'row']}>
            <Box className="business-page-left-column" width={[1, null, null, 2 / 3]}>
              <MainInfo
                listing={listing}
                logo={logo}
                handleLoadError={handleLoadError}
                expanded={expanded}
                expandedText={expandedText}
                classes={classes}
              />
              {listing.specialOffer ? (
                <SpecialOffer
                  expanded={expanded}
                  specialOffer={listing.specialOffer}
                  expandedText={expandedText}
                  handlePanelChange={handlePanelChange}
                  classes={classes}
                />
              ) : (
                ''
              )}
              <Flex className="stepper" mt={[8]} flexDirection={['column']}>
                {listing ? (
                  <Stepper
                    nav={
                      <MenuNav
                        direction="row"
                        listing={listing}
                        data={navData}
                        display={['flex', 'flex', 'flex', 'none']}
                      />
                    }
                    sideNav={
                      <MenuNav
                        direction="column"
                        listing={listing}
                        listing={listing}
                        data={navData}
                        display={['none', 'none', 'none', 'flex']}
                      />
                    }
                  >
                    {navData.map((item, i) => (
                      <Box className="step" key={i} pr={[0, null, 10]}>
                        {React.cloneElement(item.component, {
                          ...props,
                          label: item.label,
                          listing,
                          Placeholder,
                          handleLoadError,
                          labelFormatter: item.labelFormatter,
                        })}
                      </Box>
                    ))}
                    }
                  </Stepper>
                ) : (
                  ''
                )}
              </Flex>
            </Box>
            <Box className="business-page-right-column" width={[1, null, null, 1 / 3]}>
              {listing.images ? (
                <ImagesWrapper>
                  <CarouselGenerator handleLoadError={handleLoadError} images={listing.images} />
                </ImagesWrapper>
              ) : null}
              {console.log(listing)}
              {listing.showHours === false ? '' : <HoursContainer listing={listing} />}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ContentListing;
