import { Carousel } from 'react-responsive-carousel';
import React from 'react';
import { useLightbox } from 'simple-react-lightbox';
import ReactPlayer from 'react-player';
import { SRLWrapper } from 'simple-react-lightbox';
import Placeholder from '../../../assets/fill-52.svg';
import { Image } from 'rebass/styled-components';

const CarouselGenerator = ({ images, videos, handleLoadError }) => {
  const openLightbox = useLightbox();
  const genCarouselImages = () => {
    const carouselImages = images
      .map((image, index) => {
        if (image.type === 'IMAGE') {
          return (
            <div
              className="Carousel-Image"
              key={index}
              onClick={() => openLightbox(index)}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                background: 'none !important',
                overflow: 'hidden',
              }}
            >
              <img src={image.url} alt="" onError={handleLoadError} style={{ maxHeight: '240px', width: 'auto' }} />
            </div>
          );
        }
      })
      .filter((value) => (value ? value : null));
    return carouselImages;
  };
  const genCarouselVideos = () => {
    const carouselVideos = videos.map((video, index) => {
      return (
        <div className="Carousel-Video" key={index}>
          {/* <ReactPlayer height="310px" width="360px" light={true} controls={true} url={video.url} playing={false} key={index}/> */}
          {/* <img src={getThumb(vide.url)} alt="" /> */}
        </div>
      );
    });
    return carouselVideos;
  };
  const options = {
    showThumbnails: false,
    display: 'none',
    enablePanzoom: false,
  };
  return (
    <div>
      <Carousel
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        dynamicHeight={false}
        height="243"
        width="317px"
      >
        {genCarouselImages()}
      </Carousel>
      <div className="hide-lightbox">
        <SRLWrapper style={{ zIndex: 1 }} options={options}>
          {genCarouselImages()}
        </SRLWrapper>
      </div>
    </div>
  );
};
export default CarouselGenerator;
