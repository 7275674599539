import styled from 'styled-components';

const ExpandedMenu = styled.div`
    margin-top: -10px;
    margin-left: 1px;
    width: 90%;
    align-self: center;

    .Expander {
        position: relative;
        color: #2ea5f2;
        align-self: flex-end;
        font-weight: normal;
        margin-left: auto;
      }
    .Expander:hover {
        color: #145f8f;
      }
    .specialOffer: {
        width: '100%',
        borderRadius: '1px',
      }
  @media (max-width: 680px) {
    width: 100%;
  }
`;
export const SpecialOfferHeading = styled.div`
  margin-top: 2px;
  color: #24be51;
  font-family: Heebo;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-left: 10px;
`;
export default ExpandedMenu;
